import React from "react";

import { Row, Col } from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import MotifColumn from "../../components/MotifColumn";
import Questions from "../../components/Questions";
import Hero from "../../components/Hero";
import Button from "../../components/Button";


import styles from'./index.module.scss';


const VerificationPage = () => {
  return (
    
    <Layout>
      <Background themeType={"oanda"}>
        <Hero backgroundType={"dark"} type={"business"}>
            <MotifColumn>
                <h1 className={styles.heading}>Get Started With Verification </h1>
                <p>To use Shift Connect’s services and join the global community, you must first go though our simple verification process. This process protects you, your funds, and your information so you can be sure you are safe and secure every step of the way.</p>
            </MotifColumn>
        </Hero>
        <div className={styles.container}>
            <Row className={styles.video} >
                <Col  xl={6}  >
                  <div className="video-container">
                    <iframe title="Verification Video Vouched" src="https://player.vimeo.com/video/473354297" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                  </div> 
                </Col> 
                <Col xl={6} className={styles.rightColumn}>
                   <h2 >The Verification Process </h2>
                   <p>Click below to verify your identity. For this process all you will need is a piece of government issued photo identification as well as a high quality web cam or mobile phone. If you're not on a mobile phone, the next step will prompt you to switch via a QR code. 
                     <br/>
                     <br/>For the best results we reccomend using your mobile phone. 
                  </p>
                  <Button
                  to= "/verification-step-2"
									className={styles.button}
							  	>
                      Get Started

                  </Button>
                </Col>
            </Row> 
            <Row className={styles.space}/>
        </div>    
      <Questions/>
      </Background>
    </Layout>
  );
};

export default VerificationPage;
